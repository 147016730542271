export const COOKIE_CHANNEL_NAME = 'XC';
export const COOKIE_GOOGLE_CLICK_IDENTIFIER_NAME = 'GCLID';
export const COOKIE_PUBLISHER_PARAMS_NAME = 'XP';

export const COOKIE_CHANNEL_EXPIRE_TIME = 30;
export const COOKIE_GOOGLE_CLICK_IDENTIFIER_EXPIRE_TIME = 30;
export const COOKIE_EXPIRES_TIME = 540;

export const GOOGLE_ANALYTICS_TRACKER_NAME = 'CustomSingleGATracker';

export const countryIsoCodes = {
  MEXICO: 'MX',
  ARGETINA: 'AR',
  BRAZIL: 'BR',
  COLUMBIA: 'CO',
  DOMINICAN_REPUBLIC: 'DO',
  PERU: 'PE',
  VENEZUELA: 'VE',
  SOUTH_AFRIGA: 'ZA',
  SPAIN: 'ES',
  UK: 'GB',
  POLAND: 'PL',
  USA: 'US'
};

export const tldRegionsMap = {
  es: countryIsoCodes.SPAIN,
  mx: countryIsoCodes.MEXICO,
  pe: countryIsoCodes.PERU
};

export const steps = {
  LastProductStep: 70,
  CampaignStep: 80,
  FinishStep: 100
};

// statuscodes: https://www.pushengage.com/api/js-async-api/
export const pushEngageStatusCodes = {
  subscribed: 1,
  denied: 2,
  closed: 3,
  somethingWentWrong: 0,
  urlNotAllowed: 20,
  browserNotSupported: 21
};

export const eventBusEvents = {
  refreshCredentials: 'auth:refresh-credentials',
  saveCredentials: 'auth:save-credentials',
  logOut: 'auth:logout',
  appLoaded: 'app:loaded',
  newDeepLink: 'app:new-app-link',
  processStart: 'app:process-start',
  processReset: 'app:process-reset'
};
