import cookies from 'js-cookie';
import { session } from './storage';
import { COOKIE_EXPIRES_TIME, COOKIE_PUBLISHER_PARAMS_NAME } from './constants';

const publisherPrefillPrefix = 'pf_';
const publisherPrefillStorageKey = 'publisher_';
const nonPublisherKeys = ['xc', 'as', 'ap'];
const nonPublisherKeyPrefixes = [publisherPrefillPrefix];

/**
 * @param {*} routeQuery
 */
function filterParams(routeQuery) {
  const query = Object.assign({}, routeQuery);

  Object.keys(query).forEach((key) => {
    // exact key match
    if (nonPublisherKeys.includes(key)) {
      delete query[key];

      return;
    }

    // key prefix match
    if (nonPublisherKeyPrefixes.findIndex((prefix) => key.indexOf(prefix) === 0) >= 0) {
      delete query[key];

      return;
    }

    let value = query[key];

    delete query[key];

    if (Array.isArray(value) && value.length > 0) {
      value = value.join();
    }

    query[key.toLowerCase()] = value;
  });

  return query;
}
/**
 * @param {*} routeQuery
 */
function filterPrefill(routeQuery) {
  const query = Object.assign({}, routeQuery);

  Object.keys(query).forEach((key) => {
    // exact key match
    if (key.indexOf(publisherPrefillPrefix) < 0) {
      delete query[key];

      return;
    }

    let value = query[key];

    delete query[key];

    if (Array.isArray(value) && value.length > 0) {
      value = value.join();
    }

    query[key.substr(publisherPrefillPrefix.length)] = value;
  });

  return query;
}

/**
 * @param  {*} urlParams
 * @param  {string} originalChannelHash
 */
function saveParams(urlParams, originalChannelHash) {
  const publisher = filterParams(urlParams);

  publisher.originalXC = originalChannelHash;

  const data = JSON.stringify({
    ...JSON.parse(cookies.get(COOKIE_PUBLISHER_PARAMS_NAME) || '{}'),
    ...publisher
  });

  cookies.set(COOKIE_PUBLISHER_PARAMS_NAME, data, {
    expires: COOKIE_EXPIRES_TIME
  });
}

/**
 * @param {*} urlParams
 */
function savePrefill(urlParams) {
  const prefill = filterPrefill(urlParams);

  if (prefill && Object.keys(prefill).length) {
    session.setItem(publisherPrefillStorageKey, JSON.stringify(prefill));
  }
}

/**
 * @returns {string}
 */
function getParams() {
  const params = cookies.get(COOKIE_PUBLISHER_PARAMS_NAME);

  if (!params) {
    return null;
  }

  return params;
}

/**
 * @returns {string}
 */
function getPrefill() {
  return session.getItem(publisherPrefillStorageKey);
}

export default {
  filterParams,
  filterPrefill,
  saveParams,
  savePrefill,
  getParams,
  getPrefill
};
