import cookies from 'js-cookie';
import * as constants from '@fiizy/utils/dist/constants';

const regexPatterns = {
  matchBeforeQuery: `^((http|https):\\/\\/)(w{3}\\.)?`,
  anyTopLevelDomain: `(.[a-z]{2,3})?(.[a-z]{2,3})\\/`,
  ampProject: `-[a-z]{2,3}(-[a-z]{2,3})?\\.cdn\\.ampproject`,
  endsWithAMP: `.+/amp(/)?$`,
  endsWithForwardSlash: `\\/`
};

const domainPatterns = [
  {
    name: 'google',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}google${regexPatterns.anyTopLevelDomain}`
  },
  {
    name: 'bing',
    clickId: 'msclkid',
    pattern: `${regexPatterns.matchBeforeQuery}bing${regexPatterns.anyTopLevelDomain}`
  },
  {
    name: 'solcreditoAmpProject',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}solcredit(o)?${regexPatterns.ampProject}`
  },
  {
    name: 'solcreditoEndsWithAMP',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}solcredit(o)?${regexPatterns.endsWithAMP}`
  },
  {
    name: 'finzmoAmpProject',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}finzmo${regexPatterns.ampProject}`
  },
  {
    name: 'finzmoEndsWithAMP',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}finzmo${regexPatterns.endsWithAMP}`
  },
  {
    name: 'fiizyAmpProject',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}fiizy${regexPatterns.ampProject}`
  },
  {
    name: 'fiizyEndsWithAMP',
    clickId: 'gclid',
    pattern: `${regexPatterns.matchBeforeQuery}fiizy${regexPatterns.endsWithAMP}`
  }
];

export function getNewChannelHash({ urlParams, referrer, channelHashes, forceChannel }) {
  // Get channel from url 'XC' param
  // Channel URL param is the master that overrides all other
  const channelFromUrl = channelFromUrlParams(urlParams);

  if (isChannelSet(channelFromUrl)) {
    return channelFromUrl;
  }

  if (forceChannel) {
    // Channel from entry - it is used as our landing page
    if (isChannelSet(channelHashes.default)) {
      return channelHashes.default;
    }
  }

  if (isExternalReferrer(referrer)) {
    if (isReferralSeo(urlParams, referrer, channelHashes)) {
      return channelHashes.seo;
    }

    // Channel from entry - it is used as our landing page
    if (isChannelSet(channelHashes.default)) {
      return channelHashes.default;
    }
  }

  // Channel from cookie
  if (isChannelSet(channelHashes.saved)) {
    return channelHashes.saved;
  }

  // Channel from calculator
  return channelHashes.calculator;
}

export function isExternalReferrer(referrer) {
  return !doesCurrentDomainMatchReferrerDomain(referrer) && !!referrer;
}

export function isReferralSeo(urlParams, referrer, channelHashes) {
  // Is referral SEO and SEO channel is defined
  // Some of the landing pages do not have SEO channel definition
  // and are not indexed in google search in those cases we should
  // never read it as SEO traffic.
  return isChannelSet(channelHashes.seo) && isSeoReferral(referrer) && isPublisherClickIdParams(referrer, urlParams);
}

export function getSavedChannel() {
  return cookies.get(constants.COOKIE_CHANNEL_NAME);
}

export function channelFromUrlParams(urlParams) {
  if (!urlParams) {
    return false;
  }

  const channel = urlParams.get('xc');

  if (!channel) {
    return false;
  }

  // This is an edge case for historycal reasons.
  // One of the publishers used to send channel multiple channels to param
  // coma concated. All channel were the same tho, so taking first one.
  if (channel.indexOf(',')) {
    const channels = channel.split(',');

    return channels[0];
  }

  return channel;
}

export function setChannel(value) {
  if (!value) {
    return;
  }

  cookies.set(constants.COOKIE_CHANNEL_NAME, value, {
    expires: constants.COOKIE_CHANNEL_EXPIRE_TIME
  });
}

export function setGoogleClickIdentifier(params) {
  if (!params) {
    return;
  }

  if (params.get('gclid')) {
    cookies.set(constants.COOKIE_GOOGLE_CLICK_IDENTIFIER_NAME, params.get('gclid'), {
      expires: constants.COOKIE_GOOGLE_CLICK_IDENTIFIER_EXPIRE_TIME
    });
  }
}

export function getGoogleClickIdentifier() {
  const gclid = cookies.get(constants.COOKIE_GOOGLE_CLICK_IDENTIFIER_NAME);

  if (!gclid) {
    return null;
  }

  return gclid;
}

export function getAnalyticsClientID() {
  try {
    if (window.ga && typeof window.ga === 'function' && window.ga.getByName) {
      const tr = window.ga.getByName(constants.GOOGLE_ANALYTICS_TRACKER_NAME);

      if (tr) {
        const aci = tr.get('clientId');

        if (aci) {
          return aci;
        }
      }
    }

    if (window.analytics_client_id) {
      return window.analytics_client_id;
    }
  } catch (error) {
    return null;
  }

  return null;
}

export function isSeoReferral(referrer) {
  if (!referrer) {
    return false;
  }

  return isReferrerInPatterns(referrer);
}

// On channel change remove publisher params
export function invalidatePublisherParams(originalChannel, newChannel) {
  if (originalChannel !== newChannel) {
    cookies.remove(constants.COOKIE_PUBLISHER_PARAMS_NAME);
  }
}

function isChannelSet(channel) {
  return !(!channel || channel.trim() === '');
}

export function doesCurrentDomainMatchReferrerDomain(referrer) {
  if (!referrer) {
    return false;
  }

  const domainPatterns = [
    {
      pattern: `${regexPatterns.matchBeforeQuery}${window.location.hostname}${regexPatterns.endsWithForwardSlash}`
    }
  ];

  return isReferrerInPatterns(referrer, domainPatterns);
}

function isReferrerInPatterns(referrer, patterns = domainPatterns) {
  for (const pattern in patterns) {
    const regex = new RegExp(patterns[pattern].pattern);

    if (regex.test(referrer)) {
      return true;
    }
  }

  return false;
}

function isPublisherClickIdParams(referrer, urlParams) {
  for (const pattern in domainPatterns) {
    const regex = new RegExp(domainPatterns[pattern].pattern);

    if (regex.test(referrer) && !!urlParams.get(domainPatterns[pattern].clickId)) {
      return false;
    }
  }

  return true;
}

export function getFbpAndFbc() {
  const fbc = cookies.get('_fbc') || null;
  const fbp = cookies.get('_fbp') || null;

  return Object.entries({ fbc: fbc, fbp: fbp }).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});
}
