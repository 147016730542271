import tracking from '@fiizy/tracking';
import publisher from '@fiizy/utils/dist/publisher';
import URLSearchParams from '@ungap/url-search-params';

const IMPRESSION_ENDPOINT = '/api/v1/impression';

function checkIfCalculatorExists() {
  return !document.getElementById('calculator-app') && !document.getElementsByClassName('calculator-service').length;
}

(function fanalytics() {
  if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
    checkIfCalculatorExists() && makeImpression();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      checkIfCalculatorExists() && makeImpression();
    });
  }
})();

async function makeImpression() {
  const urlParams = new URLSearchParams(window.location.search);

  const originalChannelHash = tracking.getSavedChannel();

  const channelHashes = {
    seo: app.config.seoChannelHash,
    default: app.config.channelHash,
    saved: originalChannelHash,
    calculator: app.config.calculatorChannelHash
  };

  const newChannelHash = tracking.getNewChannelHash({ urlParams, referrer: document.referrer, channelHashes });

  tracking.invalidatePublisherParams(originalChannelHash, newChannelHash);
  tracking.setGoogleClickIdentifier(urlParams);

  if (!app.config.ignoreSavingXc) {
    tracking.setChannel(newChannelHash);
  }

  // Save publisher data
  const urlParamsJSON = getURLSearchParamsJSON(urlParams);

  publisher.saveParams(urlParamsJSON, originalChannelHash);
  publisher.savePrefill(urlParamsJSON);

  // Create and set params for impression
  const impressionParams = new URLSearchParams();

  impressionParams.set('lang', app.config.lang);
  impressionParams.set('document_referrer', document.referrer);
  impressionParams.set('client_milliseconds_since_epoch', new Date().getTime().toString());
  impressionParams.set('channel_hash', newChannelHash);

  const gaClientID = tracking.getAnalyticsClientID();

  if (gaClientID) {
    impressionParams.set('analytics_client_id', gaClientID);
  }

  const gClickID = tracking.getGoogleClickIdentifier();

  if (gClickID) {
    impressionParams.set('google_click_id', gClickID);
  }

  const responseData = await getImpression(impressionParams, originalChannelHash);

  if (responseData.impression_hash) window.impression_hash = responseData.impression_hash;
}

function getURLSearchParamsJSON(params) {
  const json = {};

  for (const pair of params.entries()) {
    json[pair[0]] = pair[1];
  }

  return json;
}

async function getImpression(impressionParams, originalChannelHash) {
  const url = `${app.config.apiUrl}${IMPRESSION_ENDPOINT}`;
  const fetchParams = {
    method: 'GET',
    credentials: 'include'
  };

  return await fetch(getURLWithParams(url, impressionParams), fetchParams)
    .then((response) => {
      // return a promise that resolves with the result of parsing the body text as JSON
      return response.json();
    })
    .then((data) => {
      // impression success
      return data;
    })
    .catch(() => {
      // TODO: do we check the caught error contents?
      tracking.setChannel(app.config.channelHash);
      tracking.invalidatePublisherParams(originalChannelHash, app.config.channelHash);
      impressionParams.set('channel_hash', app.config.channelHash);

      fetch(getURLWithParams(url, impressionParams), fetchParams);
    });
}

function getURLWithParams(url, params) {
  return `${url}?${params.toString()}`;
}
